html, body {
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}
